import React from "react"
import { graphql } from "gatsby"
import Loadable from "@loadable/component"
import Breadcrumbs from "~/ui/compositions/navigation/breadcrumb"
import Page from "~/models/page"

import SEO from "~/ui/helpers/seo"
import Frame from "~/ui/layout/frame"
import MarkdownBlock from "../compositions/generic/markdown"

import {
  TermsOfServiceQuery,
  ContentfulPage,
} from "../../../types/graphql-types"

type Props = {
  data: TermsOfServiceQuery
}

function TermsOfService({ data }: Props) {
  const Analytics = Loadable(() => import("../helpers/analytics"))
  const page = new Page(data.contentfulPage as ContentfulPage)

  return (
    <Frame>
      <Analytics page={page} />
      <SEO page={page} />
      <React.Fragment>
        <section>
          <div className="max-w-screen-xl mx-auto pt-6 lg:pt-10 px-4 md:px-10 xl:px-20">
            <h1 className="mb-3 lg:mb-8 text-3xl lg:text-6xl font-bold text-font-1">
              {page.title}
            </h1>
          </div>
          <div className="mb-2 mt-2">
            <div className="relative justify-between md:flex items-center max-w-screen-xl mx-auto py-7 lg:py-0 px-4 md:px-10 xl:px-20">
              <Breadcrumbs breadcrumbs={page.breadcrumbs} />
            </div>
          </div>
        </section>
        {page.blocks.map(block => (
          <React.Fragment key={block.id}>
            {block.name == "Markdown" && (
              <MarkdownBlock block={block} {...block.props}>
                {block.content.childMdx?.body}
              </MarkdownBlock>
            )}
          </React.Fragment>
        ))}
      </React.Fragment>
    </Frame>
  )
}

export default TermsOfService

export const query = graphql`
  query TermsOfService {
    contentfulPage(url: { pathname: { eq: "/terms-of-service/" } }) {
      ...PageFields
    }
  }
`
