import React from "react"
import cx from "classnames"

type Heading = {
  key: string
  id: string
}

type Props = {
  className?: string
  headings: [Heading]
}

const TableOfContents = ({ className, headings }: Props) => {
  return (
    <div className={cx("bg-indigo-50 rounded-lg p-6", className)}>
      <div>
        <div className="mb-3 text-xl lg:text-2xl text-font-1 font-bold">
          In this article
        </div>
        <ol>
          {headings.map((heading: any) => {
            return (
              <li
                key={heading.key}
                className="text-font-2 mb-4 cursor-pointer hover:underline list-inside list-decimal text-lg"
              >
                <a href={heading.id}>{heading.key}</a>
              </li>
            )
          })}
        </ol>
      </div>
    </div>
  )
}

export default TableOfContents
