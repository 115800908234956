import React from "react"
import { MDXProvider } from "@mdx-js/react"
import { MDXRenderer } from "gatsby-plugin-mdx"

import Block from "~/models/block"
import TableOfContents from "~/ui/components/article/table-of-contents"

const H2 = (props: any) => {
  const headings = props.block.content.childMdx?.tableOfContents.items.map(
    (heading: any) => ({
      key: heading.title,
      id: heading.url,
    })
  )
  const heading = headings.find((heading: any) => heading.key == props.children)

  return (
    <h2
      id={heading.id.substring(1)}
      className="my-4 lg:my-10 text-3xl lg:text-4xl font-bold text-font-1"
    >
      {props.children}
    </h2>
  )
}

const h3 = (props: any) => (
  <h3 className="mt-6 lg:mt-10 mb-4 text-xl lg:text-2xl font-medium text-font-1">
    {props.children}
  </h3>
)

const p = (props: any) => (
  <p className="mb-6 lg:mb-8 text-base lg:text-lg text-font-1">
    {props.children}
  </p>
)

const strong = (props: any) => (
  <strong className="font-normal text-font-1 bg-yellow-100">
    {props.children}
  </strong>
)

const ul = (props: any) => <ul className="mt-8 mb-4" {...props} />

const li = (props: any) => (
  <li className="flex items-center gap-2 lg:gap-4 mb-4 last:mb-0">
    <div className="bg-green-300 rounded-full w-4 h-4 lg:w-6 lg:h-6 flex-shrink-0"></div>
    <span className=" text-base lg:text-lg text-font-2">{props.children}</span>
  </li>
)

const blockquote = (props: any) => {
  return (
    <blockquote className="mt-4 mb-4 lg:mb-10 pl-4 lg:pl-6 border-l-2 border-blue-600 text-xl lg:text-2xl text-font-3">
      {props.children.props.children}
    </blockquote>
  )
}

const hr = () => {
  return <hr className="my-10" />
}

const th = (props: any) => {
  return (
    <th className="py-5 px-6 bg-blue-200 text-font-1 font-normal text-left">
      {props.children}
    </th>
  )
}

const td = (props: any) => {
  return (
    <td className="py-5 lg:py-7 px-6 text-sm text-gray-80 font-normal border-b border-[#F0F0F0]">
      {props.children}
    </td>
  )
}

const TOC = (props: any) => {
  const headings = props.block.content.childMdx?.tableOfContents.items.map(
    (heading: any) => ({
      key: heading.title,
      id: heading.url,
    })
  )

  return <TableOfContents headings={headings} className="mb-10 lg:mb-20" />
}

type Props = {
  children: string
  className?: string
  block: Block
}

function Markdown({ children, className, block }: Props) {
  return (
    <section id="content" className={className}>
      <div className="max-w-[738px] mx-auto py-6 lg:py-20 px-4 md:px-0">
        <MDXProvider
          components={{
            h2: props => <H2 block={block} {...props} />,
            h3,
            p,
            strong,
            ul,
            li,
            blockquote,
            hr,
            th,
            td,
            table: props => (
              <div className="rounded-3xl border border-gray-200">
                <table className="rounded-3xl overflow-hidden">
                  {props.children}
                </table>
              </div>
            ),
            TOC: props => <TOC block={block} {...props} />,
          }}
        >
          <MDXRenderer>{children}</MDXRenderer>
        </MDXProvider>
      </div>
    </section>
  )
}

export default Markdown
